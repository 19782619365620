import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';


function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1200,  // Duration of the animations in milliseconds
    });
  }, []);
  return (
    <div style={{overflowX:"hidden"}}>
{/* homestart */}
    <div className="home pb-5" >
    <div className=" col-md-10 col-11 m-auto" >
{/* navstart */}
      <div className="navbar d-flex pt-md-5 pt-3" >
        <div className='pt-5'>
        <h1 className='mulish-uniquifier ps-3' style={{fontWeight:"950"}}><ruby>REVO<rt style={{fontSize:"12px"}} className='fw-bolder p-0 m-0 open-sans-uniquifier'>JUST</rt></ruby></h1>
        {/* <img alt='logo' className='img-fluid' src='logo.png'></img> */}
        </div>
        <div className='d-flex align-items-baseline pt-5'>
        <a href='https://wa.me/+917814171047'>
        <button type="button" className="navbutton p-2  responsivefont6 me-3" ><i className="fa fa-whatsapp p-1"></i><i className="fa fa-phone p-1"></i><b>+91 7814171047</b></button> 
        </a>
        <a className="linkedin me-3 mail" href="mailto: Businesswithrevo@gmail.com">

        <p className="fs-5 bluetext d-none d-md-block mail">Businesswithrevo@gmail.com</p>
        </a>
        </div>
      </div>
{/* navend */}
      <div className="d-flex pt-5  align-items-center mt-4 cardcontainer">
        <div className="col-md-6 col-12">
          <p className="josefin-sans-uniquifier" ><p  className="responsivefont0 responsivebold p-md-0 m-md-0">Enhace your</p> <p className="bluetext pt-0 mt-0 responsivefont-1 fw-bold" > Digital Identity</p></p>
          <p className="responsivefont5 responsivebold">Revo Digital: Your Partner in Digital Marketing<br/>
          and Design Excellence</p>
        <a href='https://wa.me/+917814171047'>

        <button type="button" className="homebutton p-2 responsivefont3 me-3 p-2 responsivebold   px-5 mt-5" ><b>Let's Enhance</b></button> 
        </a>

          
        </div>


        <div className="col-6 image">
          <img src="illu.png" alt="ill" className="img-fluid"></img>
        </div>
      </div>
      </div>
      </div>
{/* homeend  */}
{/* part2  */}
      <div className="bgcolor d-block " style={{marginBottom:"20%"}}>
          <div  className=" col-md-10 col-11  m-auto">
              <div className="d-flex align-items-end">
                <div className="col-5 my-auto">
                <img src="chess.png" alt="chessboard" className="responsive "></img>
                  
                </div>
                <div className="col-7">
                  <p className="fw-bold mt-5 responsivefont mb-2 josefin-sans-uniquifier" >How We Do It?</p>
                  <p className=" fw-bold bluetext mb-5 responsivefont2 josefin-sans-uniquifier" >
                  Tailored Strategies for Your Success
                  </p>
                </div>     
              </div>
          </div>
      </div>
{/* part2 end */}
{/* part 3 */}
    <div style={{marginBottom:"10%"}}>
      <div className=" col-10 m-auto mb-5 josefin-sans-uniquifier" >
        <div className="d-flex flex-wrap">
          <div className=" col-xl-3 pb-4 me-auto " data-aos="fade-right">
              <div class="part3card-container">
                <div class="part3card">
                <div class="front-content">
                  <p>Market Analysis</p>
                </div>
                <div class="content">
                  <p class="heading">Market Analysis</p>
                  <p className="fs-4">
                  Comprehensive research to understand your marke and audience.
                  </p>
                </div>
              </div>
              </div>
          </div>

          <div className=" col-xl-3 pb-4 ms-auto" data-aos="fade-left">
              <div class="part3card-container">
                <div class="part3card">
                <div class="front-content">
                  <p>Creative Design</p>
                </div>
                <div class="content">
                  <p class="heading">Creative Design</p>
                  <p className="fs-4">
                  Visually stunning and effective designs that communicates & converts.

                  </p>
                </div>
              </div>
              </div>
          </div>

          <div className=" col-xl-3 pb-4 me-auto " data-aos="fade-right">
              <div class="part3card-container">
                <div class="part3card">
                <div class="front-content">
                  <p>Precise Campaign</p>
                </div>
                <div class="content">
                  <p class="heading">Precise Campaign</p>
                  <p className="fs-4">
                  Comprehensive research to understand your marke and audience.
                  </p>
                </div>
              </div>
              </div>
          </div>

          <div className=" col-xl-3 pb-4 ms-auto" data-aos="fade-left">
              <div class="part3card-container">
                <div class="part3card">
                <div class="front-content">
                  <p>Regular Tracking</p>
                </div>
                <div class="content">
                  <p class="heading">Regular Tracking</p>
                  <p className="fs-4">
                  Comprehensive research to understand your marke and audience.
                  </p>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
{/* part3end */}

      <div className="col-md-10 col-11 m-auto mt-5 mb-md-5" >
        <div>
        <div className="d-flex justify-content-center">
        <p className="bluetext fw-bold responsivefont josefin-sans-uniquifier">Analysis & Trend Tracking</p>
        </div>
        <div className="d-flex justify-content-center">
        <p className="responsivefont3 text-center">We analyze the latest moves of big brands and map out the trend flow,<br></br>
        crafting moodboards that keep your brand ahead of the curve.</p>
        </div>
        </div>
      </div>
{/* part3end */}

{/* part4start */}
      <div className="col-10 m-auto " >
        <div className="d-flex justify-content-center">
        <img alt="mood" src="mood.png" className="responsive"></img>
        </div>
        <div className=" text-center">
          {/* <h1 className="  blueback">“Yes, we blend effectiveness with creativity.”</h1> */}
        <button type="button" className="homebutton p-2  me-3   px-md-5  fw-semibold responsivefont2" >“Yes, we blend effectiveness <i>with creativity.</i>”</button> 

        </div>
      </div>
{/* part4 end  */}

      <div className="col-10 m-auto mt-5 mb-5 " >
      <div className="d-flex align-items-center">
                <div className="col-5">
                <img src="think.png" alt="chessboard" className="img-fluid"></img>
                  
                </div>
                <div className="col-7">
                  <div className="d-flex justify-content-around">
                    <img src="q1.png" alt="chessboard" className="responsiveque qanimate"></img>
                    <img src="q2.png" alt="chessboard" className="responsiveque qanimate2"></img>
                  </div>

                  <p className=" fw-bold bluetext mt-5 responsivefont josefin-sans-uniquifier" >Thinking How Would Revo Act</p>
                  <p className="responsivefont2 fw-semibold  mb-5">
                  Around Your Digital Presence?
                  </p>
                  <div className="d-flex justify-content-around">
                    <img src="q3.png" alt="chessboard" className="responsiveque qanimate3"></img>
                    <img src="q4.png" alt="chessboard" className="responsiveque qanimate4"></img>
                  </div>


                </div>     
              </div>
      </div>


{/* part 5  */}
      <div className="bluetext text-center " style={{marginTop:"12%"}}>
        <h1 style={{fontSize:"4.3vw"}} className='londrina-solid-thin' >LET US TELL YOU SOMETHING</h1>
        <div className="yellowback">
        <h1 style={{fontSize:"4.6vw"}} className='londrina-outline-regular fw-bold'>LET US TELL YOU SOMETHING</h1>
        </div>
        <h1 style={{fontSize:"4.9vw"}} className='londrina-solid-thin'>LET US TELL YOU SOMETHING</h1>

      </div>

      {/* part 6 */}
      <div className="d-flex flex-row-reverse" style={{marginTop:"12%"}} >
      <div className="col-11 lightback text-center">       
        <div className="d-flex flex-column">
        <p className='responsivefont4 mt-3 fw-medium pb-0 mx-3 mx-md-0'>Designs we brew focuses on 3 Main Attributes        </p>
        <p className=' fw-bold mt-0 pt-0 responsivefont3 mx-3 mx-md-0' >High Reability + Easy Visual Flow + Call to Action</p>
        </div>
      </div>
      </div>
      {/* part 7 */}
      <img alt='shoe' src='shoe.png' className='responsive my-5 d-none d-md-block'></img>
      <img alt='shoe' src='shoe2.png' className='responsive my-5 d-md-none'></img>


      {/* part 8 */}
      <div div className="col-11 lightback text-center" >
        <div >
          <p className=' p-md-5 p-3 fw-medium responsivefont4'><b>A/B testing-based campaigns</b> to help experiment and understand what potential 
          customers find appealing or unappealing about a design and message conveyed</p>
        </div>
      </div>
{/* part 9 */}
      <div className="col-10 m-auto " >

        <div className='d-flex justify-content-between' style={{marginTop:"8%"}}>
          <div>
        <img src="ab1.png" alt="chessboard" className="responsive p-2 imgshadow" data-aos="fade-right"></img>
        <p className='fw-bold text-center' style={{fontSize:"4vw"}}>A</p>
        </div>
        <div>
        <img src="ab2.png" alt="chessboard" className="responsive p-2 imgshadow" data-aos="fade-left"></img>
        <p className='fw-bold text-center' style={{fontSize:"4vw"}}>B</p>
        </div>
        </div>

        <div className='d-flex justify-content-center'>
        <div>
        <img src="ab3.png" alt="chessboard" className="responsive p-2 shadow" data-aos="fade-up"></img>
        <p className='fs-4 p-2 text-center' >A/B Testing based determination about which ads <b>shall be in the Skippable and which should not be.</b></p>
        </div>
        </div>
      </div>


{/* part 10 */}
      <div className="d-flex flex-row-reverse" style={{marginTop:"12%"}} >
      <div className="col-11 blueback ">       
        <div className="d-flex flex-column">
        <p className='fs-3 p-4   pb-0 text-light'><b> We stretch your hard earn funds for maximum impact!</b> Our budget-based
        campaigns are crafted with precision, blending creativity and strategy you see in real time      </p>
        </div>
      </div>
      </div>
{/* part 11 */}
      {/* <div className='d-flex justify-content-center' style={{marginTop:"12%"}}>
      <img src="pic.png" alt="chessboard" className="responsive shadow"></img>


      </div> */}

      {/* part12  */}
      <div className='gradblue'>
      <div className='col-10 mx-auto' style={{marginTop:"12%"}}>
        <div className='d-flex justify-content-center '>
          <p className='  pt-5 mt-md-5 col-6' > <b className='responsivefont4'>Stay in the loop without lifting a finger! </b><br></br>
            <span className='text-decoration-none d-none d-md-block' style={{fontSize:"1.8vw"}}>We keep you updated with regular, real-time insights on 
            campaigns, leads, and conversions—because we believe 
            in transparency and results.</span></p>
            <div className='col-6'>
            <img src="part12.png" alt="chessboard" className="responsive pt-0 mt-md-0 mt-4"></img>
            </div>
        </div>

        <div className='col-10 col-md-12'>
            <img src="part12b.png" alt="chessboard" className="responsive pt-5 mt-md-5"></img>

          </div>
      </div>
          
      </div>

      {/* part 13 */}
      <div className='col-md-10 col-11 m-auto' >
        <div className='d-flex justify-content-between margin20' >
        <div>
        <img src="add2.png" alt="chessboard" className="responsive p-3 mt-5 mt-md-0"></img>
        </div>
        <div>
        <img src="add1.png" alt="chessboard" className="responsive p-3 mt-5 mt-md-0"></img>
        </div>
        <div>
        <img src="add3.png" alt="chessboard" className="responsive p-3 mt-5 mt-md-0"></img>
        </div>

        </div>

      </div>

      {/* part 14  */}
      <div className='blueback d-flex justify-content-center align-items-center'>
        <div className='col-6'>
        <p  className='ms-auto fw-bold text-light responsivefont text-end josefin-sans-uniquifier'> THAT’S HOW WE DO IT</p>
        </div>
        <div className='col-md-4 col-6'>
        <img src="smile.png" alt="chessboard" className=""></img>
        </div>

      </div>
      <div>
        <p className='text-center m-3 fst-italic responsivefont2' >Creativity and effectiveness blended together</p>
      </div>
      {/* part15 */}
      <div className='col-md-8 col-10 m-auto' >
        <div className='d-flex justify-content-center' style={{marginTop:"12%"}}>
            <div className='col-8'>
            <p className=" fw-bold mt-5 responsivefont" > Now You Wonder</p>
            <p className="fs-1 fw-bold bluetext mb-5 josefin-sans-uniquifier">
            How would we rationally bill you?
            </p>
            </div>
            <div className='col-4 align-items-center'>
            <img src="rup.png" alt="chessboard" className="responsive my-auto"></img>
            </div>
        </div>
      </div>
{/* part16 */}
      <div className='gradblue2'>
        <div className='col-md-10 col-11 m-auto'>
          <div className=' d-flex flex-wrap '>
            <div className='d-flex flex-wrap'>
            <div className='col-12 col-md-6'>
            <img src="p1.png" alt="plans" className="img-fluid me-5 mb-5"></img>
            </div>
            <div className='col-12 col-md-6'>
            <img src="p2.png" alt="plans" className="img-fluid mb-5"></img>
            </div>
            </div>
            <div>
            <img src="p3.png" alt="plans" className="img-fluid me-5 mb-5"></img>
            </div>
            <div>
            <img src="p4.png" alt="plans" className="img-fluid mb-5 "></img>
            </div>
          </div>
        </div>
      </div>
      {/* part17 */}
      <div   className='text-center margin20'>
        <p  className='fw-semibold m-0 responsivefont2'> So What are you waiting for?</p>
        <p className='fw-bold darkbluetext responsivefont-1' > Get onboard today!</p>
      </div>
      <img src="end.png" alt="chessboard" className="img-fluid"></img>
      <p className='text-center darkbluetext fs-1'>And Enhance Your Brand’s Digital Activity</p>


      <div className='blueback d-flex justify-content-center align-items-center' style={{marginBottom:"6%",marginTop:"6%"}}>
      <a href='https://wa.me/+917814171047'>
      <button type="button" className=" p-md-4  p-1 m-4 endbutton responsivefont5" ><i className="fa fa-whatsapp p-1"></i><i className="fa fa-phone p-1 d-none d-md-inline"></i><b>+91 7814171047</b></button> 
      </a>
      <a className="linkedin me-3" href="mailto: Businesswithrevo@gmail.com">
      <p className=" text-light m-3 responsivefont4" >Businesswithrevo@gmail.com</p>
      </a>
      </div>


    </div>
  );
}

export default Home;
